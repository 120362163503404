
<template>
  <div class="">
    <MainHeader />
    <div class="px-md-0 px-3">
      <div class="container">
        <ul class="breadcrumb">
          <li>página inicial</li>
          <li>dados do humano</li>
          <li>agentes</li>        
          <li>confirmação agentes</li>        
      
        </ul>
        <div class="mt-3">
          <h1>Revise os dados do(s) seu(s) agente(s)!</h1>
          <p>confira se todos os dados estão corretos para impressão do crachá. se precisar corrigir, é só clicar em "editar dados".</p>
        </div>
      </div>
    </div>
    <div class="bg-2">
        <div class="container">
          <h2 class="d-block px-3 px-md-0">Dados do crachá</h2>
        </div>
    </div>
    <div class="container">
      <div class="row my-5 px-4 px-md-0" v-if="agents">
        <div v-for="index in 3" class="col-12 col-md-4" :key="index">
          <div v-if="agents[index-1]" class="revisionData">
            <div>
              <span class="badge">{{index}}</span>
            </div>
            <p style="text-transform:uppercase">{{agents[index-1].name}}</p>
            <p style="text-transform:uppercase">CODINOME: {{agents[index-1].codename}}</p>
            <p style="text-transform:uppercase">ESPECIALIDADE: {{agents[index-1].specialty}}</p>
          </div>
        </div>
      </div>
      
      <div class="row mb-5 pb-5 mt-3 btns">
        <div class="col-md-auto col-10 mb-md-0 mb-3">
          <router-link :to="{ name: 'FormDMF' }" class="btn btn-secondary"><span class="d-block mx-4 my-2">Editar Dados</span></router-link>
        </div>
        <div class="col-md-auto col-10">
          <router-link :to="{ name: 'RevisionHuman' }" class="btn btn-primary"><span class="d-block mx-5 my-2">Avançar</span></router-link>
          
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainHeader from "@/components/MainHeader.vue";
import axios from 'axios';
const apiUrl = 'https://cms-cdsg.up.railway.app/api';
//const apiUrl = 'https://cms-cdsg.up.railway.app/api';
//const apiUrl = 'http://localhost:1337/api';';
export default {
  name: 'RevisionAgents',
  components: {
    MainHeader
  }, 
  data() {
    return {
      agents:{},
      cdsg_store: null,
      active: false,
      count: 0
    };
  },
  methods:{
    
    async allowForm() {
      const urlObj = new URL(window.location.href);
      const params = new URLSearchParams(urlObj.search);
      const stToken = params.get('st_token') || sessionStorage.getItem('st_token');
      if (stToken) {
        try {
          const response = await axios.get(`${apiUrl}/dmf-stores?filters[url_key][$eq]=${stToken}&filters[active][$eq]=true`);
          if (response && response.data && response.data.data && response.data.data[0]) {
            sessionStorage.setItem('st_token', stToken);
            this.cdsg_store = response.data.data[0];
            console.log(this.cdsg_store)
          } else {
            sessionStorage.removeItem('st_token');
          }
        } catch (error) {
          console.log(error);
          sessionStorage.removeItem('st_token');
        }
      } else {
        sessionStorage.removeItem('st_token');
      }

      try {
        const response = await axios.get(`${apiUrl}/lp-dmf`);
        this.active = response.data.data.attributes.active;
      } catch (error) {
        console.log(error);
      }

      if (!this.cdsg_store && !this.active && sessionStorage.getItem('bd') != 1) {
        window.location.href = "/";
      }

      try {
        const resp1 = await axios.get(`${apiUrl}/dmf-agent-count`);
        this.count = resp1.data.data.attributes.count;
      } catch (error) {
        console.log(error);
      }
    }, 
    nextStep(){        
        this.$router.push('/obrigado')      
    },
  },
  mounted(){
    let storedAgents = sessionStorage.getItem('dmf_agents')
    if(storedAgents){
      this.agents = JSON.parse(storedAgents)
    }
  },
  async created(){
    this.allowForm()
  }
};
</script>
<style scoped>
  h1{color:var(--color2);font-weight:900;
  text-transform:uppercase;font-size:34px;margin: 0 0 1rem 0;}
  h1 + p{
    font-weight: 600;
    margin-bottom: 2rem;
    color: #000;
  }
  h2{
    color: var(--black);
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 900;
    padding: 1.5rem 0 1.8rem 0;
  }

  .btn-secondary{background: var(--color2);color:var(--black)}

  .badge{
    background: var(--color2);
    border-radius: 50%;
    color: var(--black);
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    width: 28px;
    padding-bottom: 8px;
    margin-bottom: 1rem;
  }
  @media(max-width:990px){
    h1{
      font-size: 22px;
    }
    h1 + p{font-size: 14px;}
    h2{
      padding: 1rem 0 1.2rem 0;
      font-size: 16px;
    }
    .revisionData p{font-size: 14px;}
    .btn.btn-primary{width: 100%;}
    .btns{justify-content:center}
  }
</style>