<template>
  <div class="popup">
    <div class="popup-content">
      <label>{{ title }}</label>
      <p>{{ message }}</p>

      <div v-if="type == false" class="popup-buttons">
        <button @click="handleYes">SIM</button>
        <button @click="handleNo">NÃO</button>
      </div>
      <div v-else class="popup-buttons">
        <button @click="handleClose">OK</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    }
  },
  methods: {
    handleYes() {
      this.$emit('popupHandleYes');
    },
    handleNo() {
      this.$emit('popupHandleNo');
    },
    handleClose() {
      this.$emit('popupClose');
    }
  }
};
</script>

<style scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index:99;
}

.popup-content {
  border-radius: 1rem;
}

.popup-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.popup-buttons button {
  padding: 5px 10px;
}

.popup-content{
  background: var(--color2);
  box-shadow: 0px 4px 4px rgba(0,0,0,.25);
  text-align: center;
  width: 440px;
  max-width: 100%;
  padding: 2rem 4rem;

}
.popup label{
  display: block;
  color: var(--black);
  font-weight: 600;
  font-size: 20px;  
  margin-bottom: 1rem;
  text-decoration: underline;
}
.popup p{font-size: 20px;font-weight: 600;color: var(--black);line-height: initial;}
.popup .popup-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}
.popup .popup-buttons button{
  font-size: 15px;
  line-height: 44px;
  border-radius: 44px;
  padding: 0 2rem calc(44px * .05) 2rem;
  border: 0;
  background: var(--black);
  color: var(--white);
  font-weight: bold;
}
@media(max-width:990px){
  .popup-content{max-width: 80%;padding:2rem}
  .popup p{font-size: 16px;}
}
</style>
