<!-- Header.vue -->
<template>
  <header>
    <div class="container text-center py-md-4 py-2">
         <img :src="require('@/assets/cdsg_top_logo.svg')" alt="DMF">
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      pageTitle: "Meu Aplicativo"
    };
  }
};
</script>
    
<style scoped>
/* Estilos específicos do componente do cabeçalho */

</style>
