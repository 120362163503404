
<template>
  <div>
    <MainHeader />
    <div v-if="isLoading" class="loading-spinner">
      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
    <PopupMessage :title="popupTitle" :message="popupMessage" :type="popupIsInformative"  @popupHandleYes="handleYes" @popupHandleNo="handleNo" @popupClose="popupClose" v-if="showPopup" /> 
    <div class="bg-white pb-5 px-3 px-md-0">
      <div class="container pb-md-5">
        <ul class="breadcrumb">
          <li>página inicial</li>
          <li>dados do humano</li>
        </ul>
        <h1 class="text-left">Vamos lá, humano...</h1>
        <div class="row">
          <div class="col-12 col-md-5">
            <div class="form-group">
              <label for="">Nome</label>
              <input type="text" v-model="form.first_name" class="form-control" id="">
            </div>
            <div class="form-group">
              <label for="">Sobrenome</label>
              <input type="text" v-model="form.last_name" class="form-control" id="">
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="">CPF</label>
                    <input type="text" v-model="form.doc" v-mask="'###.###.###-##'" class="form-control" id="" @change="checkCPF(form.doc)">
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="">Telefone</label>
                    <input type="text" v-model="form.phone" v-mask="'(##) #####-####'" class="form-control" id="">
                  </div>
                </div>
              </div>
            </div>
            
            <div class="form-group">
              <label for="">Data de Nascimento</label>
              <input type="text" v-model="form.birth_date" v-mask="'##/##/####'" class="form-control" id="">
            </div>
            <div class="form-group">
              <label for="">E-mail</label>
              <input type="email" v-model="form.email" class="form-control" id="">
            </div>            
          </div>
          <div class="col-12 col-md-5">
            <div class="form-group">
              <label for="">CEP</label>
              <input type="text" v-mask="'#####-###'" v-model="form.zip" class="form-control" id="" @change="getAddress(form.zip)">
            </div>
            <div class="form-group">
              <label for="">Endereço</label>
              <input type="text" class="form-control  " v-model="form.address" id="">
            </div>
            <div class="row">
              <div class="col-6 col-md-6">
                <div class="form-group">
                  <label for="">Número</label>
                  <input type="text" class="form-control" v-model="form.address_number" id="">
                </div>
              </div>
              <div class="col-6 col-md-6">
                <div class="form-group">
                  <label for="">Complemento</label>
                  <input type="text" class="form-control" v-model="form.complement" id="">
                </div>
              </div>
            </div>
            
            <div class="form-group">
              <label for="">Bairro</label>
              <input type="text" class="form-control  " v-model="form.neighborhood" id="">
            </div>
            <div class="row">
              <div class="col-12 col-md-10">
                <div class="form-group">
                  <label for="">Cidade</label>
                  <input type="text" class="form-control " v-model="form.city" id="">
                </div>
              </div>
              <div class="col-12 col-md-2">
                <div class="form-group">
                  <label for="">Estado</label>
                  <input type="text" class="form-control " v-model="form.state" id="" >
                </div>
              </div>
            </div>                      
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <hr class="mt-2 mb-4">
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-check">
              <input type="checkbox" class="form-check-input" id="" v-model="form.privacy_terms_read">
              <label class="form-check-label" for="">declaro ter lido e aceito os termos de privacidade</label>
            </div>
            <div class="form-check">
              <input type="checkbox" class="form-check-input" id="" v-model="form.accept_newsletter">
              <label class="form-check-label" for="">aceito receber comunicações do Cansei de ser gato</label>
            </div>
            <button type="button" @click="nextStep()" class="btn py-2 px-5 btn-primary mt-4"><span class="mx-5">Avançar</span></button>

            
            <!-- <router-link :to="{ name: 'FormDMF' }" ><span>Avançar</span></router-link>        -->
      
          </div>
        </div>        
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import MainHeader from "@/components/MainHeader.vue";
import PopupMessage from "@/components/PopupMessage.vue";
const apiUrl = 'https://cms-cdsg.up.railway.app/api';
//const apiUrl = 'https://cms-cdsg.up.railway.app/api';
//const apiUrl = 'http://localhost:1337/api';';
export default {
    name: 'HumanDMF',
    components: {
        MainHeader,
        PopupMessage
    },
    data() {
      return {
        popupMessage: '',
        popupTitle:'',
        showPopup:false,
        isLoading: false,
        lastdoc:"",
        form:{
          first_name:'',
          last_name:'',
          doc:'',
          birth_date:'',
          email:'',
          zip:'',
          address:'',
          address_number:'',
          complement:'',
          neighborhood:'',
          city:'',
          state:'',
          privacy_terms_read:'',
          accept_newsletter:'',
          phone:'',
          
        },
        cdsg_store: null,
        active: false,
        count: 0
      };
    },
    methods:{
      async allowForm() {
        const urlObj = new URL(window.location.href);
        const params = new URLSearchParams(urlObj.search);
        const stToken = params.get('st_token') || sessionStorage.getItem('st_token');
        if (stToken) {
          try {
            const response = await axios.get(`${apiUrl}/dmf-stores?filters[url_key][$eq]=${stToken}&filters[active][$eq]=true`);
            if (response && response.data && response.data.data && response.data.data[0]) {
              sessionStorage.setItem('st_token', stToken);
              this.cdsg_store = response.data.data[0];
              console.log(this.cdsg_store)
            } else {
              sessionStorage.removeItem('st_token');
            }
          } catch (error) {
            console.log(error);
            sessionStorage.removeItem('st_token');
          }
        } else {
          sessionStorage.removeItem('st_token');
        }

        try {
          const response = await axios.get(`${apiUrl}/lp-dmf`);
          this.active = response.data.data.attributes.active;
        } catch (error) {
          console.log(error);
        }

        if (!this.cdsg_store && !this.active && sessionStorage.getItem('bd') != 1) {
          window.location.href = "/";
        }

        try {
          const resp1 = await axios.get(`${apiUrl}/dmf-agent-count`);
          this.count = resp1.data.data.attributes.count;
        } catch (error) {
          console.log(error);
        }
      },      
      async checkCPF(cpf){
        this.isLoading = true
        const resp = await axios.get(`${apiUrl}/webhooks/registro-dmf?exists=check&id=${cpf}`)
        console.log(resp)
        this.isLoading = false

        if(resp.data == true){
          this.lastdoc = this.form.doc;
          this.popupTitle = 'atenção, humano:'
          this.popupMessage = `cpf ${this.lastdoc} já está sendo utilizado`
          this.popupIsInformative = true,
          this.showPopup = true
          this.form.doc = ""
          return false
          
        }
      },
      async getAddress(cep){
        this.isLoading = true
        try {
          const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
          if (response.data.erro) {
            console.log('CEP não encontrado');
          } else {
            console.log('Endereço encontrado:', response.data);
            this.form.address = response.data.logradouro
            this.form.neighborhood = response.data.bairro
            this.form.city = response.data.localidade
            this.form.state = response.data.uf
            // Aqui você pode manipular os dados de endereço conforme necessário
          }
        } catch (error) {
          console.error('Erro ao buscar endereço:', error);
        }
        this.isLoading = false
      },
      nextStep(){
        sessionStorage.setItem('dmf_human',JSON.stringify(this.form))
        for (const key in this.form) {
          console.log(this.form[key])
          if (!this.form[key] && key != "complement") {
            console.log(key)
            this.popupTitle = 'atenção, humano:'
            this.popupMessage = 'preencha todas as informações para o seu cadastro'
            this.popupIsInformative = true,
            this.showPopup = true
            return false
          }
        }
        
        
        this.$router.push('/cadastro-de-agentes-ultra-secretos')
       
      },
      popupHandleYes(){
      },
      popupHandleNo(){
      },
      popupClose(){
        this.showPopup = false
        //alert(`eita`)
      }

    },
    mounted(){
      let storedHuman = sessionStorage.getItem('dmf_human')
      if(storedHuman){
        this.form = JSON.parse(storedHuman)
      }
    },
    async created(){
      this.allowForm()
    }
};
</script>
<style scoped>
  h1{color:var(--color1);font-weight:900;
    text-align: left;
  text-transform:uppercase;font-size:34px;margin: 0 0 3rem 0;}
    .readonly{pointer-events:none;background:#f2f2f2}
  @media(max-width:990px){
    h1{font-size:22px;}
    .form-check label{font-size: 12px;}
    .btn-primary{width: 100%;}
  }
</style>