import { createApp } from 'vue'

import StartPage from './components/StartPage.vue';
import HistoryDMF from './components/HistoryDMF.vue';
import HumanDMF from './components/HumanDMF.vue';
import FormDMF from './components/FormDMF.vue';
import EndingPage from './components/EndingPage.vue';
import RevisionHuman from './components/RevisionHuman.vue';
import RevisionAgents from './components/RevisionAgents.vue';
import CheckoutRedirect from './components/CheckoutRedirect.vue';
import RegisterRevision from './components/RegisterRevision.vue';
import App from './App.vue'
import VueTheMask from 'vue-the-mask';
import { createRouter, createWebHistory } from 'vue-router';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap'
import './assets/css/style.css';

const router = createRouter({
    history: createWebHistory(),
    routes: [
      {
        path: '/',
        name: 'StartPage',
        component: StartPage,
      },  
      {
        path: '/prepare-se',
        name: 'HistoryDMF',
        component: HistoryDMF,
      }, 
      {
        path: '/cadastro-de-humano',
        name: 'HumanDMF',
        component: HumanDMF,
      }, 
      {
        path: '/cadastro-de-agentes-ultra-secretos',
        name: 'FormDMF',
        component: FormDMF,
      }, 
      {
        path: '/revisao-humano',
        name: 'RevisionHuman',
        component: RevisionHuman,
      },
      {
        path: '/revisao-agentes',
        name: 'RevisionAgents',
        component: RevisionAgents,
      }, 
      {
        path: '/obrigado',
        name: 'EndingPage',
        component: EndingPage,
      },   
      {
        path: '/checkout-ok',
        name: 'CheckoutRedirect',
        component: CheckoutRedirect,
      },
      {
        path: '/revisao-interna',
        name: 'RegisterRevision',
        component: RegisterRevision,
      },      
    ],
  });
  router.beforeEach((to, from, next) => {
    // Verifica se a rota atual é diferente da anterior
    if (to !== from) {
      // Rola a página para o topo com uma animação suave
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    next();
  });
createApp(App).use(router).use(VueTheMask).mount('#app')
