
<template>
  <div>
    <MainHeader />

    <div class="container pt-5 justify-content-center text-center">
      <div class="balloon mx-auto" >
        <img :src="require('@/assets/step1_3.svg')" alt="DMF">
        <h1>Obrigado!</h1>
        <p>Agora estamos aguardando o processamento de pagamento de sua taxa.</p>
        <p>Fique de olho em seu e-mail! Todas as atualizações sobre o seu cadastro serão enviadas para o e-mail informado.</p>

              
      </div>
      <img :src="require('@/assets/step1_1.png')" alt="DMF">
    </div>
  </div>
</template>

<script>
import MainHeader from "@/components/MainHeader.vue";

export default {
    name: 'CheckoutRedirect',
    components: {
        MainHeader
    },
    data() {
    return {

      };
    },
    
};
</script>

<style scoped>
  .balloon + img{margin-top:-5rem}
  .loading-spinner{
    background: rgba(0,0,0,1);
  }
  p{max-width: 80%;}
</style>