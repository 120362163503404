
<template>
  <div>
    <MainHeader />
    <div v-if="count == 0" class="loading-spinner">
      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
    <div class="container pt-5 justify-content-center text-center" v-if="cdsg_store || backdoor || (active && (count > 0 && count < limitCount))">
      <div class="balloon mx-auto" >
        <img :src="require('@/assets/step1_3.svg')" alt="DMF">
        <p>prepare-se para fazer parte da</p>
        <h1>Dominação<br>Mundial<br>Felina</h1>
        <router-link :to="{ name: 'HistoryDMF' }" class="btn btn-primary">Começar</router-link>       
      </div>
      <img :src="require('@/assets/step1_1.png')" alt="DMF">
    </div>
    <div class="container pt-5 justify-content-center text-center"  v-else>
      <div class="balloon mx-auto" >
        <img :src="require('@/assets/step1_3.svg')" alt="DMF">
        <h1>Ops...</h1>
        <p>No momento as inscrições estão encerradas.</p>
              
      </div>
      <img :src="require('@/assets/step1_1.png')" alt="DMF">
    </div>
  </div>
</template>

<script>
import MainHeader from "@/components/MainHeader.vue";
import axios from 'axios' 
//const apiUrl = 'https://cms-cdsg.up.railway.app/api';
  const apiUrl = 'https://cms-cdsg.up.railway.app/api';
//const apiUrl = 'http://localhost:1337/api';';
export default {
    name: 'StartPage',
    components: {
        MainHeader
    },
    data() {
    return {
      active:false,
      count:0,
      limitCount:0,
      backdoor:false,
      cdsg_store : false
      };
    },
    methods:{
      async allowForm(){
      
        const urlObj = new URL(window.location.href);

        // Use URLSearchParams para obter o valor do parâmetro 'st_token'
        const params = new URLSearchParams(urlObj.search);
        const stToken = params.get('st_token');
        if(stToken){
          await axios.get(`${apiUrl}/dmf-stores?filters[url_key][$eq]=${stToken}&filters[active][$eq]=true`)
          .then(response => {
            if(response && response.data && response.data.data && response.data.data[0]){
              console.log(`st_token`,response.data.data[0])
              sessionStorage.setItem(`st_token`,stToken)
              this.cdsg_store = response.data.data[0]
            }else{
              sessionStorage.removeItem(`st_token`)
            }
          })
          .catch(error => {
              console.log(error);
              sessionStorage.removeItem(`st_token`)
          });
          
        }else{
          sessionStorage.removeItem(`st_token`)
        }

        await axios.get(`${apiUrl}/lp-dmf`)
        .then(response => {
          console.log(response)
          this.active = response.data.data.attributes.active

          
        })
        .catch(error => {
            console.log(error);
        });
        
        const resp1 = await axios.get(`${apiUrl}/dmf-agent-count`)
        
        this.count = resp1.data.data.attributes.count
        this.limitCount = resp1.data.data.attributes.limit
        
      }
    },
    async created(){      
      this.allowForm() 
      this.backdoor = window.location.search.includes('backdoor=true') ? true : false
      sessionStorage.setItem(`bd`,this.backdoor ? 1 : 0)
    }
};
</script>

<style scoped>
  .balloon + img{margin-top:-5rem}
  .loading-spinner{
    background: rgba(0,0,0,1);
  }
</style>