<template>
  <div>
    <router-view></router-view>

  </div>
</template>

<script>

export default {
  name: 'App',

}
</script>
<style>
:root{
  --color1:#EB5C42;
  --color2:#F4E9D3;
  --black:#000;
  --white:#fff;
  --gray:#D9D9D9;
  --btn-height:50px;
}
</style>
