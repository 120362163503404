<template>
  <div>
    <MainHeader />
    <PopupMessage :title="popupTitle" :message="popupMessage" :type="popupIsInformative" @popupHandleYes="handleYes" @popupHandleNo="handleNo" @popupClose="popupClose" v-if="showPopup" />
    <div v-if="isLoading" class="loading-spinner">
      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
    <div class="bg-white pb-5 px-3 px-md-0 vh100">
      <div class="container pb-5">
        <ul class="breadcrumb">
          <li>página inicial</li>
          <li>dados do humano</li>
          <li>agentes</li>
          <li v-if="agentOnboard">cadastro de agente</li>
        </ul>
        <div v-if="agentOnboard">
          <div>
            <h1>Cadastro de Agente</h1>
            <p>tamanho máximo da foto: 2mb</p>
          </div>
          <div class="agentCard">
            <img :src="require('@/assets/step_agent_2.svg')" alt="DMF Brand">
            <button type="button" class="catholder position-relative mt-3">
              <input type="file" @change="handleFileChange($event)" accept=".jpg,.jpeg,.png">
              <div v-if="agentOnboardData.photo[0]" class="hasPhoto">
                <img :src="agentOnboardData.photo[0].url"/>
              </div>
              <div v-else>
                <svg width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="35.4061" cy="35.3719" r="35.1547" fill="white"/>
                  <line x1="35.6934" y1="17.1884" x2="35.6934" y2="53.5553" stroke="#EB5C42" stroke-width="3"/>
                  <line x1="17.2227" y1="35.0841" x2="53.5896" y2="35.0841" stroke="#EB5C42" stroke-width="3"/>
                </svg>
              </div>
            </button>
            <input type="text" style="text-transform:uppercase;" v-model="agentOnboardData.name" class="name" placeholder="NOME" maxlength="9" />
            <div class="field">
              <label for="">Codinome:</label>
              <input type="text" v-model="agentOnboardData.codename" maxlength="18">
            </div>
            <div class="field">
              <label for="">Especialidade:</label>
              <input type="text" v-model="agentOnboardData.specialty" maxlength="18"/>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-12">
              <button type="button" @click="saveAgent()" class="btn py-2 px-5 btn-primary mt-4"><span class="mx-5">Finalizar</span></button>
            </div>
          </div>
        </div>
        <div v-else>
          <div>
            <h1>Nossos agentes</h1>
            <p>cadastro limitado a 1 CPF com até 3 agentes.</p>
          </div>
          <div class="row agents-row">
            <div class="col-10 col-md-10">
              <div class="row ">
                <div v-for="index in 3" class="col-md-4 col-12 mb-md-0 mb-4" :key="index">
                  <div class="panel agent">
                    <div v-if="agents[index-1]" class="p-5">
                      <div class="agentPhoto">
                        <img :src="agents[index-1].photo[0].url"/>
                      </div>
                      <div class="agentName">{{agents[index-1].name}}</div>
                      <div class="agentCodename"><label class="d-block">Codinome:</label>{{agents[index-1].codename}}</div>
                      <div class="agentSpecialty"><label class="d-block">Especialidade:</label>{{agents[index-1].specialty}}</div>
                      <button type="button" @click="editAgent(index-1)" class="btn btn-secondary d-block mt-4">
                        Editar
                      </button>
                    </div>
                    <div v-else class="d-flex flex-column p-5">
                      <button type="button" @click="addAgent()" class="catholder">
                        <div>
                          <svg width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="35.4061" cy="35.3719" r="35.1547" fill="white"/>
                            <line x1="35.6934" y1="17.1884" x2="35.6934" y2="53.5553" stroke="#EB5C42" stroke-width="3"/>
                            <line x1="17.2227" y1="35.0841" x2="53.5896" y2="35.0841" stroke="#EB5C42" stroke-width="3"/>
                          </svg>
                        </div>
                      </button>
                      <button type="button" @click="addAgent()" class="btn btn-primary">
                        Adicionar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-5" v-if="agents.length > 0">
            <div class="col-12">
              <button type="button" @click="nextStep()" class="btn py-2 px-5 btn-primary mt-4"><span class="mx-5">Avançar</span></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal for image cropper -->
    <div v-if="showCropper" class="cropper-modal">
      <div class="cropper-container">
        <img ref="image" :src="croppingImage" alt="Image for cropping">
        <div class="cropper-buttons">
          <button @click="cancelCrop" class="btn btn-secondary">Cancelar</button>
          <button @click="cropImage" class="btn btn-primary">Cortar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EXIF from 'exif-js';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import MainHeader from "@/components/MainHeader.vue";
import axios from 'axios';
import PopupMessage from "@/components/PopupMessage.vue";
const apiUrl = 'https://cms-cdsg.up.railway.app/api';
//const apiUrl = 'https://cms-cdsg.up.railway.app/api';
//const apiUrl = 'http://localhost:1337/api';';

export default {
  name: 'FormDMF',
  components: {
    MainHeader,
    PopupMessage
  },
  data() {
    return {
      popupMessage: '',
      popupTitle: '',
      showPopup: false,
      agentOnboard: false,
      agentOnboardData: {
        photo: [],
        codename: '',
        specialty: '',
        name: '',
        agentOrder: 0
      },
      agents: [],
      isLoading: false,
      showCropper: false,
      croppingImage: null,
      cropper: null,
      cdsg_store: null,
      active: false,
      count: 0
    };
  },
  methods: {
    async allowForm() {
      const urlObj = new URL(window.location.href);
      const params = new URLSearchParams(urlObj.search);
      const stToken = params.get('st_token') || sessionStorage.getItem('st_token');
      if (stToken) {
        try {
          const response = await axios.get(`${apiUrl}/dmf-stores?filters[url_key][$eq]=${stToken}&filters[active][$eq]=true`);
          if (response && response.data && response.data.data && response.data.data[0]) {
            sessionStorage.setItem('st_token', stToken);
            this.cdsg_store = response.data.data[0];
            console.log(this.cdsg_store)
          } else {
            sessionStorage.removeItem('st_token');
          }
        } catch (error) {
          console.log(error);
          sessionStorage.removeItem('st_token');
        }
      } else {
        sessionStorage.removeItem('st_token');
      }

      try {
        const response = await axios.get(`${apiUrl}/lp-dmf`);
        this.active = response.data.data.attributes.active;
      } catch (error) {
        console.log(error);
      }

      if (!this.cdsg_store && !this.active && sessionStorage.getItem('bd') != 1) {
        window.location.href = "/";
      }

      try {
        const resp1 = await axios.get(`${apiUrl}/dmf-agent-count`);
        this.count = resp1.data.data.attributes.count;
      } catch (error) {
        console.log(error);
      }
    },  
    handleFileChange(event) {
      const file = event.target.files[0];
      if (!file) return;
      const reader = new FileReader();
      reader.onload = (e) => {
        this.croppingImage = e.target.result;
        this.showCropper = true;
        this.$nextTick(() => {
          const imageElement = this.$refs.image;
          this.cropper = new Cropper(imageElement, {
            aspectRatio: 1,
            viewMode: 1,
            autoCropArea: 1,
            responsive: true,
            zoomable: true,
            scalable: true,
            background: false,
            modal: true,
          });
        });
      };
      reader.readAsDataURL(file);
    },
    cropImage() {
      const canvas = this.cropper.getCroppedCanvas();
      canvas.toBlob((blob) => {
        const croppedFile = new File([blob], 'cropped.jpg', { type: 'image/jpeg' });
        this.uploadImage(croppedFile);
        this.showCropper = false;
      });
    },
    cancelCrop() {
      this.showCropper = false;
      this.cropper.destroy();
    },
    async uploadImage(file) {
      this.isLoading = true;
      try {
        const formData = new FormData();
        formData.append('files', file);
        const correctedFile = await this.correctImageOrientation(file);
        formData.set('files', correctedFile);
        const response = await axios.post(apiUrl + `/upload/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        this.agentOnboardData.photo = response.data;
      } catch (error) {
        alert('Erro ao realizar envio da foto:', error);
      }
      this.isLoading = false;
    },
    correctImageOrientation(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function (event) {
          const image = new Image();
          image.onload = function () {
            EXIF.getData(image, function () {
              const orientation = EXIF.getTag(this, 'Orientation');
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');

              switch (orientation) {
                case 2: ctx.transform(-1, 0, 0, 1, image.width, 0); break;      // flip horizontal
                case 3: ctx.transform(-1, 0, 0, -1, image.width, image.height); break;  // rotate 180
                case 4: ctx.transform(1, 0, 0, -1, 0, image.height); break;    // flip vertical
                case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;        // transpose
                case 6: ctx.transform(0, 1, -1, 0, image.height, 0); break;    // rotate 90
                case 7: ctx.transform(0, -1, -1, 0, image.height, image.width); break;  // transverse
                case 8: ctx.transform(0, -1, 1, 0, 0, image.width); break;      // rotate -90
                default: ctx.transform(1, 0, 0, 1, 0, 0);      // no transformation
              }

              if ([5, 6, 7, 8].indexOf(orientation) > -1) {
                canvas.width = image.height;
                canvas.height = image.width;
              } else {
                canvas.width = image.width;
                canvas.height = image.height;
              }

              ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

              canvas.toBlob(function (blob) {
                resolve(new File([blob], file.name, { type: file.type }));
              }, file.type);
            });
          };
          image.src = event.target.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.readAsDataURL(file);
      });
    },
      getAddress(cep){
        return cep
      },
      nextStep(){
        this.$router.push('/revisao-agentes')
      },
      addAgent(){
        this.agentOnboard = true
        this.agentOnboardData.agentOrder = this.agents.length
      },
      editAgent(order){
        this.agentOnboard = true
        this.agentOnboardData = this.agents[order]
      },
      saveAgent(){
        console.log(this.agentOnboardData)
        if(this.agentOnboardData.photo.length == 0){
          alert("Adicione uma foto do agente")
          return false
        }
        if(this.agentOnboardData.name == ""){
          alert("Informe o nome do agente")
          return false
        }
        if(this.agentOnboardData.codename == ""){
          alert("Informe o codinome do agente")
          return false
        }
        if(this.agentOnboardData.specialty == ""){
          alert("Informe a especialidade do agente")
          return false
        }
        this.agents[this.agentOnboardData.agentOrder] = this.agentOnboardData
        this.agentOnboardData = {
          photo:[],
          codename:'',
          specialty:'',
          name:'',
          agentOrder: this.agents.length
        }
        this.agentOnboard = false
        sessionStorage.setItem('dmf_agents',JSON.stringify(this.agents))
      },
      popupHandleYes(){
        alert('top')
      },
      popupHandleNo(){
        alert('ixi')
      },
      popupClose(){
        this.showPopup = false
      }

    },
    mounted(){
      let storedAgents = sessionStorage.getItem('dmf_agents')
      if(storedAgents){
        this.agents = JSON.parse(storedAgents)
      }
    },
    async created(){
      this.allowForm()
    }
};
</script>

<style scoped>
h1 {
  color: var(--color1);
  font-weight: 900;
  text-align: left;
  text-transform: uppercase;
  font-size: 34px;
  margin: 0 0 1rem 0;
}
h1 + p {
  font-weight: 600;
  margin-bottom: 3rem;
}

.panel {
  border: 1px solid #d9d9d9;
  border-radius: 1rem;
}
.catholder {
  border: 0;
  background: #d9d9d9;
  aspect-ratio: 1;
  margin-bottom: 1rem;
}
@media (max-width: 990px) {
  h1 {
    font-size: 22px;
  }
  h1 + p {
    font-size: 14px;
  }
  .btn.btn-primary {
    width: 100%;
  }
}
</style>

<style scoped>
/* Estilos para o modal do cropper */
.cropper-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity:1
}

.cropper-container {
  background: #fff;
  padding: 0px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 90%;
  max-height: 90%;
  overflow: hidden;
}

.cropper-container img {
  max-width: 100%;
  max-height: 70vh;
  height: auto;
  margin-bottom: 10px;
  
}

.cropper-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: fixed;
    bottom: 1rem;
    right: 1rem;
    width: fit-content;
}

.cropper-buttons button {
  margin: 5px;
}
</style>
