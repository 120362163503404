<template>
  <div>
    <MainHeader />
    <div class="bg-2 pt-md-5 pt-1 pb-5">
      <h1 class="text-center pt-md-5 mt-md-5 mb-md-5 pt-5">
        <small>são mais de</small>
        10 mil<br>
        agentes
      </h1>
    </div>
    <img :src="require('@/assets/step2_1.png')" alt="Agentes DMF" class="w-100 d-none d-md-block">
    <img class="d-block d-md-none w-100" :src="require('@/assets/step2_mobile_1.png')" alt="História CDSG">
    <div class="bg-1 py-md-5 mt-5">
      <div class="container-small">
        <p class="f-24 text-center text-white px-md-0 px-5">
          O movimento da <b>Dominação Mundial Felina</b> é a busca pelo conforto absoluto de todos os gatos. como lider do movimento, eu espalho a palavra felina para recrutar agentes há mais de 10 anos.
        </p>
        <img :src="require('@/assets/step2_2.png')" alt="História CDSG">
      </div>
      <h2 class="mb-5">Vantagens <small class="d-block mt-3">de ser um agente DMF</small></h2>      
      <div class="bg-white py-md-5 benefits">
        <div class="container">
          <carousel  :items-to-show="4" :breakpoints="breakpoints" :wrap-around="true">
            <slide v-for="item in items" :key="item.text">
              <!-- Conteúdo do slide -->
              <div>
                <div class="ico">
                  <img :src="item.img" alt="Chico">
                </div>
                <p>{{ item.text }}</p>
              </div>
            </slide>
            <template #addons>
              <navigation />
              <pagination />
            </template>
          </carousel>
        </div>
      </div>
    </div>
    <div class="container-small last text-center mb-5 pb-5">
      <img :src="require('@/assets/step2_3.svg')" alt="Chico" class="mb-md-5 mb-3 mt-5">
      <p v-if="!cdsg_store" class="px-md-5 px-5 text-center text-white font-weight-500">
        o cadastro do seu gato como agente oficial da DMF é totalmente gratuito. ao finalizar o cadastro, eu mesmo enviarei o crachá do seu agente para o seu endereço.
        <b class="d-block mt-4">cadastro limitado a 1 CPF com até 3 agentes.</b>
        <b class="d-block mt-1">ao final do cadastro será cobrada uma taxa fixa de R$ 10,00 referente ao envio do(s) crachá(s)</b>
      </p>
      <p v-else class="px-md-5 px-5 text-center text-white font-weight-500">
        o cadastro do seu gato como agente oficial da DMF é totalmente gratuito. ao finalizar o cadastro, ele será impresso imediatamente, então não se esqueça de conferir os dados.
        <b class="d-block mt-4">cadastro limitado a 1 CPF com até 3 agentes.</b>
      </p>
      <router-link :to="{ name: 'HumanDMF' }" class="btn py-2 px-5 btn-primary mt-4">Iniciar Cadastro</router-link>       
    </div>
  </div>
</template>

<script>
import MainHeader from "@/components/MainHeader.vue";
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import axios from 'axios' 
const apiUrl = 'https://cms-cdsg.up.railway.app/api';
//const apiUrl = 'https://cms-cdsg.up.railway.app/api';
//const apiUrl = 'http://localhost:1337/api';';

export default {
  name: 'HistoryDMF',
  components: {
    MainHeader,
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      items: [
        {
          img: require('@/assets/step2_6.svg'),
          text: "acesso a informações secretas da DMF"
        },
        {
          img: require('@/assets/step2_7.svg'),
          text: "presentes especiais do Cansei de ser gato"
        },
        {
          img: require('@/assets/step2_4.svg'),
          text: "descontos exclusivos no meu site e loja física"
        },
        {
          img: require('@/assets/step2_5.svg'),
          text: "acesso em primeira mão aos meus lançamentos"
        },
      ],
      breakpoints: {
        // 700px and up
        990: {
          itemsToShow: 4,
          snapAlign: '',
        },
        0: {
          itemsToShow: 1,
          snapAlign: 'center',
        },
      },
      cdsg_store: null,
      active: false,
      count: 0
    };
  },
  methods: {
    async allowForm() {
      const urlObj = new URL(window.location.href);
      const params = new URLSearchParams(urlObj.search);
      const stToken = params.get('st_token') || sessionStorage.getItem('st_token');
      if (stToken) {
        try {
          const response = await axios.get(`${apiUrl}/dmf-stores?filters[url_key][$eq]=${stToken}&filters[active][$eq]=true`);
          if (response && response.data && response.data.data && response.data.data[0]) {
            sessionStorage.setItem('st_token', stToken);
            this.cdsg_store = response.data.data[0];
            console.log(this.cdsg_store)
          } else {
            sessionStorage.removeItem('st_token');
          }
        } catch (error) {
          console.log(error);
          sessionStorage.removeItem('st_token');
        }
      } else {
        sessionStorage.removeItem('st_token');
      }

      try {
        const response = await axios.get(`${apiUrl}/lp-dmf`);
        this.active = response.data.data.attributes.active;
      } catch (error) {
        console.log(error);
      }

      if (!this.cdsg_store && !this.active && sessionStorage.getItem('bd') != 1) {
        window.location.href = "/";
      }

      try {
        const resp1 = await axios.get(`${apiUrl}/dmf-agent-count`);
        this.count = resp1.data.data.attributes.count;
      } catch (error) {
        console.log(error);
      }
    }
  },
  async created() {
    await this.allowForm();
  }
};
</script>

<style scoped>
h1 {
  font-size: 7rem;
  font-weight: 900;
  color: var(--color1);
  line-height: 5.5rem; 
}
h1 small {
  display: block;
  color: var(--black);
  font-weight: 600;
  font-size: 2rem;
  line-height: 2rem;
}
h2 {
  font-size: 7rem;
  font-weight: 900;
  color: var(--color2);
  line-height: 5.5rem; 
  text-transform: lowercase;
  text-align: center;
}
h2 small {
  display: block;
  font-size: 22px;
  color: var(--black);
  font-weight: 600;
}

.benefits {
  font-weight: 600;
}
.benefits p {
  max-width: 200px;
  margin: 0 auto;
  text-align: center;
}
.benefits .ico {
  margin: 0 auto 1rem auto;
  display: block;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-primary {
  font-size: 20px;
}

@media(max-width: 990px) {
  h1 {
    font-size: 70px;
    line-height: 60px;
  }
  h1 small {
    font-size: 22px;
  }
  h2 {
    font-size: 60px;
    line-height: 50px;
  }
  h2 small {
    line-height: 30px;
  }
  .container-small.last p {
    font-size: 12px;
  }
  .container-small.last img {
    height: 50px;
  }

  .bg-white {
    background-color: var(--color1) !important;
  }

  .carousel__slide > div {
    background: #fff;
    padding: 3rem 1rem;
  }
}
</style>
